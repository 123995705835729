import React from "react"
import { Route, Switch } from "react-router-dom"
import { PrivateRoute } from "./PrivateRoute"

import path from "./pathSettings"
import ComingSoon from "../components/Page-ComingSoon"
import EmailChange from "../components/Page-Settings-Email-Change"
import EmailVerify from "../components/Page-Settings-Email-Verify"
import Home from "../components/Page-Home"
import Login from "../components/Page-Login"
import MobileChange from "../components/Page-Settings-Mobile-Change"
import MobileVerify from "../components/Page-Settings-Mobile-Verify"
import PatrolRecords from "../components/Page-Patrol-Records"
import PatrolRecordsProfile from "../components/Page-Patrol-Records-Profile"
import PasswordChange from "../components/Page-Settings-Password-Change"
import Settings from "../components/Page-Settings"
import TicketRecordsCreate from "../components/Page-Ticket-Records-Create"
import TicketRecordsProfile from "../components/Page-Ticket-Records-Profile"
import TicketRecords from "../components/Page-Ticket-Records"

const RoutePath = () => {
    
    return (
        <Switch>
            <Route path={path("login")} component={Login} />
            <PrivateRoute path={path("patrolRecordsProfile", [":id"])} component={PatrolRecordsProfile} />
            <PrivateRoute exact path={path("patrolRecords")}  component={PatrolRecords} />
            <PrivateRoute path={path("preferences")} component={ComingSoon} />
            <PrivateRoute path={path("emailChange")} component={EmailChange} />
            <PrivateRoute path={path("emailVerify")} component={EmailVerify} />
            <PrivateRoute path={path("mobileChange")} component={MobileChange} />
            <PrivateRoute path={path("mobileVerify")} component={MobileVerify} />
            <PrivateRoute path={path("passwordChange")} component={PasswordChange} />
            <PrivateRoute path={path("settings")} component={Settings} />
            <PrivateRoute path={path("ticketRecordsCreate", [":id"])} component={TicketRecordsCreate} />
            <PrivateRoute path={path("ticketRecordsProfile", [":id"])} component={TicketRecordsProfile} />
            <PrivateRoute path={path("ticketRecords")} component={TicketRecords} />
            <PrivateRoute exact path={path("home")} component={Home} />
        </Switch>
    )
}

export default RoutePath