const path = (page, payload) => {
    let path = "/"
    switch (page) {
        case "login":
            path = "/login"
            break
        case "patrolRecordsProfile":
            path = "/patrol/records/" + payload[0]
            break
        case "patrolRecords":
            path = "/patrol/records"
            break
        case "preferences":
            path = "/settings/preferences"
            break
        case "deployment":
            path = "/deployment"
            break
        case "emailChange":
            path = "/settings/email/change"
            break
        case "emailVerify":
            path = "/settings/email/verify"
            break
        case "mobileChange":
            path = "/settings/mobile/change"
            break
        case "mobileVerify":
            path = "/settings/mobile/verify"
            break
        case "passwordChange":
            path = "/settings/password/change"
            break
        case "settings":
            path = "/settings"
            break
        case "ticketRecordsCreate":
            path = "/ticket/records/create"
            break
        case "ticketRecordsProfile":
            path = "/ticket/records/" + payload[0]
            break
        case "ticketRecords":
            path = "/ticket/records"
            break
        case "home":
            path = "/"
            break
        default:
    }
    return path
}

export default path