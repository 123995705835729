import React, { useContext, useEffect, useState } from "react"
import { CloseOutlined } from "@ant-design/icons";

import { AppContext } from "../contexts/AppContext"

const FilterAppliedTicket = (props) => {
    const { appState } = useContext(AppContext)
    const [values, setValues] = useState({})
    const [display, setDisplay] = useState({})
    const [visibility, setVisibility] = useState(false)

    useEffect(() => {
        setValues(props.values)

        let dateRange = ""
        if (props.values.dateRange.length === 2) {
            dateRange = props.values.dateRange[0].format("DD MMM YYYY") + " - " + props.values.dateRange[1].format("DD MMM YYYY")
        }

        let priority = ""
        if (props.values.priority !== 0) {
            priority = props.values.priority.slice(0, 1).toUpperCase() + props.values.priority.slice(1).toLowerCase()
        }

        let status = ""
        if (props.values.status !== 0) {
            status = props.values.status.slice(0, 1).toUpperCase() + props.values.status.slice(1).toLowerCase()
        }

        let site = ""
        if (props.values.site !== 0) {
            for (let i = 0; i < appState.siteList.length; i++) {
                // if (props.values.site === appState.siteList[i].securitySiteID) {
                if (props.values.site === appState.siteList[i].id) {
                    site = appState.siteList[i].name
                    break
                }
            }
        }

        let client = ""
        if (props.values.client !== 0) {
            for (let i = 0; i < appState.clientList.length; i++) {
                if (props.values.client === appState.clientList[i].id) {
                    client = appState.clientList[i].name
                    break
                }
            }
        }

        let category = ""
        if (props.values.category !== 0) {
            category = props.values?.category?.slice(0, 1).toUpperCase() + props?.values?.category?.slice(1).toLowerCase()
        }

        if (props.values.search === "" && dateRange === "" && priority === "" && status === "" && site === "" && client === "" && category === "") {
            setVisibility(false)
        }
        else {
            setVisibility(true)
        }

        setDisplay({
            search: props.values.search,
            dateRange: dateRange,
            priority: priority,
            status: status,
            site: site,
            client: client,
            category: category,
        })

    }, [props])

    const handlefilterClick = (e, id) => {
        props.onFinish({
            ...values,
            [id]: id === "search" ? ("") : (0)
            // [id]: id === "search" ? ("") : (id === "dateRange" ? ([]) : (0))
        })
    }

    return (
        <div>
            {
                visibility === true ? (
                    <div className="filter-applied">
                        <div className="filter-applied-item">
                            Ticket Created Date Range: <b>{display.dateRange}</b>
                        </div>
                        {
                            display.search !== "" ? (
                                <div className="filter-applied-item">
                                    Search: <b>{display.search}</b>&nbsp;
                                    <span className="close" onClick={(e) => handlefilterClick(e, "search")}>
                                        <CloseOutlined />
                                    </span>
                                </div>
                            ) : (
                                <div />
                            )
                        }
                        {
                            display.priority !== "" ? (
                                <div className="filter-applied-item">
                                    Priority: <b>{display.priority}</b>&nbsp;
                                    <span className="close" onClick={(e) => handlefilterClick(e, "priority")}>
                                        <CloseOutlined />
                                    </span>
                                </div>
                            ) : (
                                <div />
                            )
                        }
                        {
                            display.status !== "" ? (
                                <div className="filter-applied-item">
                                    Status: <b>{display.status}</b>&nbsp;
                                    <span className="close" onClick={(e) => handlefilterClick(e, "status")}>
                                        <CloseOutlined />
                                    </span>
                                </div>
                            ) : (
                                <div />
                            )
                        }
                        {
                            display.site !== "" ? (
                                <div className="filter-applied-item">
                                    Site: <b>{display.site}</b>&nbsp;
                                    <span className="close" onClick={(e) => handlefilterClick(e, "site")}>
                                        <CloseOutlined />
                                    </span>
                                </div>
                            ) : (
                                <div />
                            )
                        }
                        {
                            display.client !== "" ? (
                                <div className="filter-applied-item">
                                    Client: <b>{display.client}</b>&nbsp;
                                    <span className="close" onClick={(e) => handlefilterClick(e, "client")}>
                                        <CloseOutlined />
                                    </span>
                                </div>
                            ) : (
                                <div />
                            )
                        }
                        {
                            display.category !== "" ? (
                                <div className="filter-applied-item">
                                    Category: <b>{display.category}</b>&nbsp;
                                    <span className="close" onClick={(e) => handlefilterClick(e, "category")}>
                                        <CloseOutlined />
                                    </span>
                                </div>
                            ) : (
                                <div />
                            )
                        }
                    </div>
                ) : (
                    <div />
                )
            }

        </div>
    )
}

export default FilterAppliedTicket