import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Descriptions, Empty, notification, Spin, Table, Tag } from "antd"
import moment from "moment"
import API from "@aws-amplify/api"

import { listClockingReport } from "../backend/graphql/queries"
import path from "../utils/pathSettings"
import { AppContext } from "../contexts/AppContext"
import CardPatrol from './Component-Card-Patrol'
import FilterPatrol from './Component-Filter-Patrol'
import FilterAppliedPatrol from './Component-FilterApplied-Patrol'

const PatrolRecords = () => {
    const { appState, actionSetPageTitle } = useContext(AppContext)
    const history = useHistory()
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0,
            defaultPageSize: 20,
            showSizeChanger: true,
        },
        loading: false
    })
    const [displayList, setDisplayList] = useState([])
    const [values, setValues] = useState({
        search: "",
        dateRange: [],
        status: 0,
        site: 0,
    })

    const getPatrolRecords = async (props, values) => {
        // console.log("values", values);
        setTable({
            ...table,
            loading: true
        })
        const user = JSON.parse(localStorage.getItem("isap_cognitouser_client"))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        // console.log("user", user);
        // console.log("profiles", profiles);
        try {
            let filter = {
                securityAgencyID: {
                    eq: profiles[0].securityAgencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                },
                and: [
                    {
                        start: { ge: values.dateRange[0].toISOString().split(".")[0] }
                    },
                    {
                        start: { le: values.dateRange[1].toISOString().split(".")[0] }
                    },
                ]
            }

            if (values.search !== "") {
                filter = {
                    ...filter,
                    or: [{ clockingRouteName: { contains: values.search } }, { staffNo: { contains: values.search } }, { staffProfileName: { contains: values.search } }]
                }
            }

            // pending backend update to include status
            if (values.status !== 0) {
                filter = {
                    ...filter,
                    status: { eq: values.status }
                }
            }

            if (values.site !== 0) {
                filter = {
                    ...filter,
                    securitySiteID: { eq: values.site }
                }
            }

            const listClockingReportDetails = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    clientProfileID: profiles[0].clientProfileID,
                    agencyID: profiles[0].securityAgencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                },
                filter: filter
            }
            // console.log("listClockingReportDetails", listClockingReportDetails);
            const result = await API.graphql({
                query: listClockingReport,
                variables: listClockingReportDetails,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })
            // console.log("result", result);
            const data = result.data.result
            // console.log("data", data);
            setTable({
                ...table,
                data: data.result,
                loading: data.result.length === 0 ? false : true,
                pagination: {
                    ...table.pagination,
                    current: props.current,
                    offset: props.offset,
                    pageSize: props.pageSize,
                    total: props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            })
        }
        catch (error) {
            console.log("error:", error);
            notification.error({
                message: "Unable to retrieve patrol routes"
            })
            setTable({
                ...table,
                loading: false,
            })
        }

        // const timer = setTimeout(() => {
        //     setTable({
        //         ...table,
        //         data: dataState.patrolRecords
        //     })
        //     clearTimeout(timer)
        // }, 1000)
    }

    useEffect(() => {
        // console.log(moment());
        const currentDatetime = moment()
        let _values = {}
        if (sessionStorage.getItem("isap_client_patrol_records") !== null) {
            // console.log(moment(JSON.parse(sessionStorage.getItem("isap_client_patrol_records")).dateRange[0]));
            _values = {
                ...values,
                ...JSON.parse(sessionStorage.getItem("isap_client_patrol_records")),
                dateRange: [
                    moment(JSON.parse(sessionStorage.getItem("isap_client_patrol_records")).dateRange[0]),
                    moment(JSON.parse(sessionStorage.getItem("isap_client_patrol_records")).dateRange[1]),
                ]
            }
        }
        else {
            _values = {
                ...values,
                dateRange: [
                    moment(new Date(currentDatetime.format("YYYY-MM-DD 00:00:00"))).subtract(1, "days"),
                    moment(new Date(currentDatetime.format("YYYY-MM-DD 23:59:59"))),
                ]
            }
        }
        setValues(_values)
        getPatrolRecords(table.pagination, _values)

        actionSetPageTitle("Patrol - Records")

        return () => {
            actionSetPageTitle("")
        }
    }, [])

    useEffect(() => {
        if (table.data !== -1) {
            setDisplayList(table.data)
            setTable({
                ...table,
                loading: false
            })
        }
    }, [table.data, appState.fixedListLoaded])

    const handleTableChange = (paginate) => {
        getPatrolRecords({
            ...table.pagination,
            current: paginate.current,
            pageSize: paginate.pageSize,
            offset: paginate.current * paginate.pageSize - paginate.pageSize,
        }, values)
    }

    const handleOnRow = (record, rowIndex) => {
        return {
            onClick: (event) => {
                // console.log(event.target.className);
                if (event.target.className !== "noRowClick" && event.target.className !== "") {
                    history.push(path("patrolRecordsProfile", [record.id]))
                }
            },
        }
    }

    const handleScoll = (e) => {
        // console.log("scroll height: ", e.target.scrollHeight);
        // console.log("scroll top", e.target.scrollTop);
        // console.log("client height", e.target.clientHeight);
        const atTop = e.target.scrollTop === 0
        const atBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
        if (atBottom) {
            // console.log("BOTTOM");
        }
        else if (atTop) {
            // console.log("TOP");
        }
    }

    const onFinish = (values) => {
        // console.log("on finish", values);
        let _values = {
            ...values,
            dateRange: [
                moment(values.dateRange[0].format("YYYY-MM-DD 00:00:00")),
                moment(values.dateRange[1].format("YYYY-MM-DD 23:59:59")),
            ]
        }
        sessionStorage.setItem("isap_client_patrol_records", JSON.stringify(_values))
        setValues(_values)
        getPatrolRecords(table.pagination, _values)
    }

    const columns = [
        {
            title: "Route Name",
            dataIndex: "clockingRouteName"
        },
        {
            title: "Site",
            dataIndex: "securitySiteName"
        },
        {
            title: "Patrol Start",
            dataIndex: "start",
            render: (text, record) => {
                return (
                    moment(new Date(text.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm')
                )
            }
        },
        {
            title: "Patrol End",
            dataIndex: "end",
            render: (text, record) => {
                return (
                    text !== "" ?
                        moment(new Date(text.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm') :
                        ""
                )
            }
        },
        {
            title: "Total Duration",
            dataIndex: "totalDuration",
            render: (text, record) => {
                // console.log(record);
                const totalDurationMilliseconds = new Date(record.end.replace(/-/g, "/") + " UTC") - new Date(record.start.replace(/-/g, "/") + " UTC")
                // const totalDurationDisplayHours = Math.floor(totalDurationMilliseconds / 1000 / 60 / 60)
                const totalDurationDisplayHours = Math.round((Math.floor(totalDurationMilliseconds / 1000 / 60 / 60) + Number.EPSILON) * 100) / 100
                // const totalDurationDisplayMinutes = Math.round((num + Number.EPSILON) * 100) / 100 
                const totalDurationDisplayMinutes = Math.round((totalDurationMilliseconds / 1000 / 60 % 60 + Number.EPSILON) * 100) / 100
                return (
                    <div>
                        {totalDurationDisplayHours !== 0 ? totalDurationDisplayHours + "h " : ""}{totalDurationDisplayMinutes !== 0 ? totalDurationDisplayMinutes + "min" : ""}
                    </div>
                )
            }
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text, record) => {
                const colour = text === "COMPLETED" ? "green" : (text === "SEMI-COMPLETED" ? "yellow" : (text === "DISRUPTED" ? "red" : "cyan"))
                return (
                    <Tag color={colour}>
                        {text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase()}
                    </Tag>
                )
            }
        },
        {
            title: "Scanned Points",
            dataIndex: "scannedPoints",
            render: (text, record) => {
                return (
                    <div>
                        {record.clockedPoint}/{record.totalPoint}
                    </div>
                )
            }
        },
        {
            title: "Remarks",
            dataIndex: "remark",
            ellipsis: true,
        },
        {
            title: "Staff Num",
            dataIndex: "staffNo",
        },
        {
            title: "Staff Name",
            dataIndex: "staffProfileName",
        },
    ]

    const renderCards = (array) => {
        const cards = array.map((item) => {
            // console.log(item);
            return (
                <CardPatrol item={item} key={item.id} />
            )
        })
        return cards
    }

    const renderCardPatrolRecords = (array) => {
        return (
            (array.length === 0) ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
                renderCards(array)
            )
        )
    }

    return (
        <div className="container-content allow-overflow">
            <div className="content-content">
                <Descriptions title={
                    <div className="row spaceBetween">
                        <div>
                            <span>{appState.profileClient.clientProfileName}</span> <Tag>Patrol</Tag>
                        </div>
                    </div>
                } />
                <FilterPatrol onFinish={onFinish} values={values} />
                <FilterAppliedPatrol onFinish={onFinish} values={values} />
            </div>
            <Table
                className="above-md"
                columns={columns}
                rowKey={record => record.id}
                dataSource={displayList}
                pagination={table.pagination}
                loading={table.loading}
                scroll={{ x: 1200, y: "55vh" }}
                onChange={handleTableChange}
                onRow={handleOnRow}
                rowClassName={"table-row-clickable"}
            />
            <div className="below-md container-flexgrow" onScroll={handleScoll}>
                <Spin spinning={table.loading}>
                    {renderCardPatrolRecords(displayList)}
                </Spin>
            </div>
        </div>
    )
}

export default PatrolRecords