import React, { useContext, useEffect, useState } from "react"
import { Descriptions, notification, Spin, Table, Tag } from "antd"
import moment from "moment"
import API from "@aws-amplify/api"

import path from "../utils/pathSettings"
import { DataContext } from "../contexts/DataContext"
import { AppContext } from "../contexts/AppContext"
import { listClockingReportWithPoints } from "../backend/graphql/custom_queries"

const PatrolRecordsProfile = (props) => {
    const { dataState } = useContext(DataContext)
    const { appState,actionSetPageTitle } = useContext(AppContext)
    const [loading, setLoading] = useState(false)
    const [patrol, setPatrol] = useState(-1)
    const [display, setDisplay] = useState({})
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0,
            defaultPageSize: 20,
            showSizeChanger: true,
        },
        loading: false
    })
    const [displayList, setDisplayList] = useState([])

    const getPatrolRecord = async (props, id) => {
        setLoading(true)
        setTable({
            ...table,
            loading: true
        })
        const user = JSON.parse(localStorage.getItem("isap_cognitouser_client"))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        try {
            const listClockingReportWithPointsDetails = {
                pagination: {
                    clientProfileID: profiles[0].clientProfileID,
                    agencyID: profiles[0].securityAgencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                },
                pagination2: {
                    limit: props.pageSize,
                    offset: props.offset,
                    clientProfileID: profiles[0].clientProfileID,
                    agencyID: profiles[0].securityAgencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                },
                filter: {
                    securityAgencyID: {
                        eq: profiles[0].securityAgencyID // current fixed to first profile, need to have an attribute to store last agency profile
                    },
                    id: {
                        eq: parseInt(id)
                    }
                },
                filter2: {
                    securityAgencyID: {
                        eq: profiles[0].securityAgencyID // current fixed to first profile, need to have an attribute to store last agency profile
                    },
                    clockingReportID: {
                        eq: parseInt(id)
                    }
                },
            }
            // console.log(listClockingReportWithPointsDetails);
            const result = await API.graphql({
                query: listClockingReportWithPoints,
                variables: listClockingReportWithPointsDetails,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })
            // console.log("result", result);
            const data = result.data.result
            const data2 = result.data.result2
            // console.log("data", data);
            // console.log("data2", data2);
            setPatrol({
                ...data.result[0]
            })
            setTable({
                ...table,
                data: data2.result,
                loading: data2.result.length === 0 ? false : true,
                pagination: {
                    ...table.pagination,
                    current: props.current,
                    offset: props.offset,
                    pageSize: props.pageSize,
                    total: props.offset  >= data2.count ? data2.count + 1 : data2.count // keeps the last pagination if it is the last record
                }
            })
        }
        catch (error) {
            console.log("error:", error);
            notification.error({
                message: "Unable to retrieve patrol record profile"
            })
            setLoading(false)
            setTable({
                ...table,
                loading: false
            })
        }

        // if (id !== undefined) {
        //     for (let i = 0; i < dataState.patrolRecords.length; i++) {
        //         if (parseInt(id) === dataState.patrolRecords[i].id) {
        //             setPatrol(dataState.patrolRecords[i])
        //             break
        //         }
        //     }
        // }
        // else {
        //     notification.error({
        //         message: "Unable to retrieve patrol record"
        //     })
        //     const timer = setTimeout(() => {
        //         setLoading(false)
        //         clearTimeout(timer)
        //     }, 1000)
        // }
    }

    // const getPatrolCheckpointRecords = () => {
    //     setTable({
    //         ...table,
    //         loading: true
    //     })
    //     const timer = setTimeout(() => {
    //         setTable({
    //             ...table,
    //             data: dataState.patrolCheckPointRecords
    //         })
    //         clearTimeout(timer)
    //     }, 1000)
    // }

    useEffect(() => {
        getPatrolRecord(table.pagination, props.match.params.id)
        // getPatrolCheckpointRecords()
        
        actionSetPageTitle("Patrol Record Profile", true, path("patrolRecords"))

        return () => {
            actionSetPageTitle("", false, "")
        }
    }, [])

    useEffect(() => {
        // if (Object.keys(incident).length !== 0) {
        if (patrol !== -1) {
            // console.log("patrol", patrol);
            const totalDurationMilliseconds = new Date(patrol.end.replace(/-/g, "/") + " UTC") - new Date(patrol.start.replace(/-/g, "/") + " UTC")
            // const totalDurationDisplayHours = Math.floor(totalDurationMilliseconds / 1000 / 60 / 60)
            const totalDurationDisplayHours = Math.round((Math.floor(totalDurationMilliseconds / 1000 / 60 / 60) + Number.EPSILON) * 100) / 100
            // const totalDurationDisplayMinutes = totalDurationMilliseconds / 1000 / 60 % 60
            const totalDurationDisplayMinutes = Math.round((totalDurationMilliseconds / 1000 / 60 % 60 + Number.EPSILON) * 100) / 100 
            setDisplay({
                ...patrol,
                datetimeStartDisplay: moment(new Date(patrol.start.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm'),
                datetimeEndDisplay: patrol.end !== "" ? moment(new Date(patrol.end.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm') : "",
                durationDisplay: totalDurationDisplayHours !== 0 ? totalDurationDisplayHours + "h " : "" + totalDurationDisplayMinutes !== 0 ? totalDurationDisplayMinutes + "min" : "",
                statusDisplay: patrol.status.slice(0, 1).toUpperCase() + patrol.status.slice(1).toLowerCase(),
                statusColour: patrol.status === "COMPLETED" ? "green" : (patrol.status === "SEMI-COMPLETED" ? "yellow" : (patrol.status === "DISRUPTED" ? "red" : "cyan"))
            })
            setLoading(false)
        }
    }, [patrol])

    useEffect(() => {
        if (table.data !== -1) {
            setDisplayList(table.data)
            setTable({
                ...table,
                loading: false
            })
        }
    }, [table.data])

    const handleTableChange = (paginate) => {
        getPatrolRecord({
            ...table.pagination,
            current: paginate.current,
            pageSize: paginate.pageSize,
            offset: paginate.current * paginate.pageSize - paginate.pageSize,
        })
    }

    const columns = [
        {
            title: "Checkpoint Name",
            dataIndex: "clockingPointName"
        },
        {
            title: "Tag ID",
            dataIndex: "tag",
            render: (text, record) => {
                return (
                    record.tag !== undefined && record.tag !== null && record.tag !== "" ? record.tag : "Not registered" 
                )
            }
        },
        {
            title: "Scanned",
            dataIndex: "status",
            render: (text, record) => {
                const colour = text === "CLOCKED" ? "green" : "red"
                return (
                    <Tag color={colour}>
                        {text === "CLOCKED" ? "Yes" : "No" }
                    </Tag>  
                )
            }
        },
        {
            title: "Datetime",
            dataIndex: "datetime",
            render: (text, record) => {
                return (
                    text !== null && text !== "" ? moment(new Date(text.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm') : ""
                )
            }
        },
        {
            title: "Remarks",
            dataIndex: "remark"
            // dataIndex: "remarks"
        },
    ]

    return (
        <div className="container-content allow-overflow">
            <Spin spinning={loading}>
                <div className="content-content">
                    <Descriptions
                        title={
                            <div>
                                <span>{appState.profileClient.clientProfileName}</span> <Tag>Patrol</Tag>
                            </div>
                        }
                        bordered
                        column={{ xs: 1, sm: 2 }}
                    >
                        <Descriptions.Item label="Route Name">{display.clockingRouteName}</Descriptions.Item>
                        <Descriptions.Item label="Total Duration">{display.durationDisplay}</Descriptions.Item>
                        <Descriptions.Item label="Datetime" span={2}>{display.datetimeStartDisplay + " - " + display.datetimeEndDisplay}</Descriptions.Item>
                        <Descriptions.Item label="Status">
                            <Tag color={display.statusColour}>
                                {display.statusDisplay}
                            </Tag>
                        </Descriptions.Item>
                        <Descriptions.Item label="Scanned Points" span={2}>{display.clockedPoint + "/" + display.totalPoint}</Descriptions.Item>
                        <Descriptions.Item label="Staff Name">{display.staffProfileName}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                        bordered
                        column={{ xs: 1, sm: 2 }}
                        layout="vertical"
                    >
                        {/* <Descriptions.Item label="Remarks" span={2}>{display.remarks}</Descriptions.Item> */}
                        <Descriptions.Item label="Remarks" span={2}>{display.remark}</Descriptions.Item>
                    </Descriptions>
                </div>
            </Spin>
            <Table
                // className="above-md"
                columns={columns}
                rowKey={record => record.clockingPointID}
                dataSource={displayList}
                // pagination={table.pagination}
                loading={table.loading}
                scroll={{ y: "55vh" }}
                onChange={handleTableChange}
            />
        </div>
    )
}

export default PatrolRecordsProfile