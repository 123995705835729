import React, { useContext, useEffect, useState } from "react"
import { Button, Comment, Descriptions, Empty, Form, Image, Input, List, notification, Popconfirm, Spin, Tag } from "antd"
import { EditOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import API from "@aws-amplify/api"
import { Storage } from "@aws-amplify/storage"
import moment from "moment"
import combineQuery from 'graphql-combine-query';
import gql from 'graphql-tag';
import { print } from 'graphql/language/printer';

import { listIncidentComment, listIncidentReport, listIncidentFile, listTicket, listTicketComment, listTicketFile } from "../backend/graphql/queries"
import { createIncidentComment, createTicketComment, submitIncident, updateTicket } from "../backend/graphql/mutations"
import path from "../utils/pathSettings"
import { AppContext } from "../contexts/AppContext"
import imgEmptyImage192 from "../media/Image-EmptyImage-192.png"
import useUnsavedChangesWarning from "../hooks/useUnsavedChangesWarning"

const { TextArea } = Input

const TicketRecordsProfile = (props) => {
    const { appState, actionSetPageTitle } = useContext(AppContext)
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [loadingCommentList, setLoadingCommentList] = useState(false)
    const [loadingImageList, setLoadingImageList] = useState(false)
    const [ticket, setTicket] = useState(-1)
    const [commentList, setCommentList] = useState(-1)
    const [imageListSource, setImageListSource] = useState([])
    const [display, setDisplay] = useState({})
    const [displayCommentList, setDisplayCommentList] = useState([])
    const validateMessages = {
        required: 'This field is required.',
    }
    const history = useHistory()
    const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning()
    const [disabledSubmit, setDisabledSubmit] = useState(true)

    const getImageFromStorage = async (array) => {
        // console.log("array", array);
        let arrayUpdate = []
        for (let i = 0; i < array.length; i++) {
            try {
                const result = await Storage.get(array[i].key)
                // console.log("result", result.toString());
                // arrayUpdate.push(result.toString())
                arrayUpdate.push({
                    ...array[i],
                    src: result.toString()
                })
            }
            catch (error) {
                console.log("error:", error);
            }
        }

        setImageListSource(arrayUpdate)
    }

    const getTicketRecord = async (id) => {
        setLoading(true)
        const user = JSON.parse(localStorage.getItem("isap_cognitouser_agency"))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        try {
            let combineQueryFn = combineQuery('CompositeMutation');

            const listTicketVariable = {
                pagination: {
                    agencyID: profiles[0].securityAgencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                },
                filter: {
                    securityAgencyID: {
                        eq: profiles[0].securityAgencyID // current fixed to first profile, need to have an attribute to store last agency profile
                    },
                    id: {
                        eq: id
                    }
                }
            }
            combineQueryFn = combineQueryFn.add(gql`${listTicket}`, listTicketVariable);


            const listTicketCommentVariable = {
                pagination: {
                    agencyID: profiles[0].securityAgencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                },
                filter: {
                    ticketID: {
                        eq: id
                    }
                }
            }
            combineQueryFn = combineQueryFn.addN(gql`${listTicketComment}`, [listTicketCommentVariable],
                (name, index) => {
                    return `${name}2_${index}`
                }, (name, index) => {
                    return `${name}2_${index}`
                });


            const listTicketFileVariable = {
                pagination: {
                    agencyID: profiles[0].securityAgencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                },
                filter: {
                    ticketID: {
                        eq: id
                    }
                }
            }
            combineQueryFn = combineQueryFn.addN(gql`${listTicketFile}`, [listTicketFileVariable],
                (name, index) => {
                    return `${name}3_${index}`
                }, (name, index) => {
                    return `${name}3_${index}`
                });

            const { document, variables } = (() => combineQueryFn)();

            console.log("Ticket", print(document), variables);
            const result = await API.graphql({
                query: print(document),
                variables,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })
            console.log("result", result);
            setTicket(result.data.result.result[0])
            setCommentList(result.data.result2_0.result);
            setImageListSource(result.data.result3_0.result);
        }
        catch (error) {
            console.log("error:", error);
            notification.error({
                message: "Unable to retrieve ticket"
            })
            setLoading(false)
        }
    }

    const getTicketCommentRecords = async (id) => {
        setLoadingCommentList(true)
        const user = JSON.parse(localStorage.getItem("isap_cognitouser_agency"))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        try {
            const listIncidentCommentDetails = {
                pagination: {
                    agencyID: profiles[0].securityAgencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                },
                filter: {
                    incidentReportID: {
                        eq: id
                    }
                }
            }
            // console.log("listIncidentCommentDetails", listIncidentCommentDetails);
            const result = await API.graphql({
                query: listIncidentComment,
                variables: listIncidentCommentDetails,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })
            // console.log("result", result);
            setCommentList(result.data.result.result)
        }
        catch (error) {
            console.log("error:", error);
            notification.error({
                message: "Unable to retrieve ticket comments"
            })
            setLoading(false)
        }

        // if (id !== undefined) {
        //     setCommentList(dataState.incidentCommentRecords)
        // }
        // else {
        //     notification.error({
        //         message: "Unable to retrieve ticket comments"
        //     })
        //     const timer = setTimeout(() => {
        //         setLoadingCommentList(false)
        //         clearTimeout(timer)
        //     }, 1000)
        // }
    }

    const getTicketFileRecords = async (id) => {
        setLoadingImageList(true)
        const user = JSON.parse(localStorage.getItem("isap_cognitouser_agency"))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        try {
            const listIncidentFileDetails = {
                pagination: {
                    agencyID: profiles[0].securityAgencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                },
                filter: {
                    incidentReportID: {
                        eq: id
                    }
                }
            }
            const result = await API.graphql({
                query: listIncidentFile,
                variables: listIncidentFileDetails,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })
            // console.log("result", result);
            const data = result.data.result
            // console.log("data", data);
            const array = data.result ? data.result : []
            if (array.length > 0) {
                await getImageFromStorage(array)
            }
        }
        catch (error) {
            console.log("error:", error);
            notification.error({
                message: "Unable to retrieve ticket files"
            })
        }
        finally {
            setLoadingImageList(false)
        }
    }

    const _createTicketComment = async (id, values) => {
        setLoadingCommentList(true)

        const user = JSON.parse(localStorage.getItem("isap_cognitouser_agency"))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        const cognitoUsername = user.signInUserSession.idToken.payload["cognito:username"]

        // console.log("user", user);
        // console.log("profiles", profiles);
        // console.log("cognitoUsername", cognitoUsername);

        try {
            const variables = {
                create: {
                    accountID: cognitoUsername,
                    staffProfileID: profiles[0].staffProfileID,
                    ticketID: id,
                    comment: values.comment,
                }
            }

            const result = await API.graphql({
                query: createTicketComment,
                variables,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })
            // console.log(result);
            notification.success({
                message: "Updated successfully"
            })
            // getTicketRecord(id)
            form.setFieldsValue({
                comment: ""
            })
            setDisabledSubmit(true)
            setPristine()
        }
        catch (error) {
            console.log("error:", error);
            notification.error({
                message: "Unable to retrieve ticket"
            })
            setLoadingCommentList(false)
        }
    }

    const updateTicketRecord = async (id) => {
        setLoading(true)

        const user = JSON.parse(localStorage.getItem("isap_cognitouser_agency"))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        // console.log("user", user);
        // console.log("profiles", profiles);
        try {
            const variables = {
                update: {
                    id: id,
                    status: 'CLOSED',

                    closedBy: user.username,
                    closedByStaffProfileID: profiles[0].staffProfileID,
                    // closedByDesignation: 
                    closedTimestamp: moment().toISOString().split('.')[0],
                }
            }
            // console.log("incidentDetails", incidentDetails);
            const result = await API.graphql({
                query: updateTicket,
                variables: variables,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })
            // console.log("result", result);
            notification.success({
                message: "Updated successfully"
            })
            // getTicketRecord(result.data.result.id)
            // getTicketCommentRecords(result.data.result.id)
            // history.push(path("incidentReport", [result.data.result.id]))
        }
        catch (error) {
            console.log("error:", error);
            notification.error({
                message: "Unable to update ticket"
            })
            setLoading(false)
            setLoadingCommentList(false)
        }
    }

    useEffect(() => {
        // getTicketRecord(props.match.params.id)
        // getTicketCommentRecords(props.match.params.id)
        // getTicketFileRecords(props.match.params.id)

        actionSetPageTitle("Ticket", true, path("ticketRecords"))

        return () => {
            actionSetPageTitle("", false, "")
        }
    }, [])

    useEffect(() => {
        // if (Object.keys(ticket).length !== 0) {
        if (ticket !== -1 && appState.clientList !== -1 && appState.siteList !== -1) {

            let siteDisplay = appState.siteList.find(s => s.id == ticket.siteProfileID)?.name;
            let client = appState.clientList.find(s => s.id == ticket?.clientProfileID);

            setDisplay({
                ...ticket,
                siteDisplay,
                clientDisplay: client?.name ?? '',
                clientEmail: client?.email ?? '',
                clientPhoneNo: client?.phoneNo ?? '',
                createdOnDisplay: moment.utc(ticket.createdOn).local().format('DD MMM YYYY (ddd), HH:mm'),
                updatedOnDisplay: moment.utc(ticket.updatedOn).local().format('DD MMM YYYY (ddd), HH:mm'),
                statusDisplay: ticket.status.slice(0, 1).toUpperCase() + ticket.status.slice(1).toLowerCase(),
                statusColour: ticket.status === "CLOSED" ? "green" : "orange"
            })
            setLoading(false)
        }
    }, [ticket, appState.clientList, appState.siteList])

    useEffect(async () => {
        if (imageListSource?.length > 0) {
            let _imageListSource = [];
            for (let i of imageListSource) {
                let src = await Storage.get(i.key);

                _imageListSource.push({
                    ...i,
                    src,
                })
            }
            setImageListSource(_imageListSource);
        }
    }, [imageListSource])

    useEffect(() => {
        if (commentList !== -1) {
            let array = []
            for (let i = 0; i < commentList.length; i++) {
                array.push({
                    ...commentList[i],
                    updatedOnDisplay: moment(new Date(commentList[i].updatedOn.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm'),
                })
            }
            setDisplayCommentList(array)
            setLoadingCommentList(false)
        }
    }, [commentList])

    const onFinish = (values) => {
        // console.log("on finish", values);
        // console.log("appState", appState);
        _createTicketComment(props.match.params.id, values)
    }

    const handleCloseCaseClick = () => {
        // console.log("close case");
        // setLoading(true)
        // setLoadingCommentList(true)
        updateTicketRecord(props.match.params.id)
    }

    const handleEditClick = () => {
        history.push(path("ticketRecordsProfileEdit", [props.match.params.id]))
    }

    const handleFormChange = (e) => {
        // console.log(e.target.value);
        if (e.target.value.length !== 0) {
            setDirty()
            setDisabledSubmit(false)
        }
        else {
            setPristine()
            setDisabledSubmit(true)
        }
    }

    const renderImages = (array) => {
        const images = array.map((item) => {
            // console.log("item", item);
            return (
                <div key={item.id}>
                    <Image
                        width={100}
                        src={item.src !== null ? item.src : ""}
                        fallback={imgEmptyImage192}
                    />
                    &nbsp;&nbsp;
                </div>
            )
        })
        return images
    }

    const renderUploadImages = (array) => {
        return (
            (array.length === 0) ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
                <div className="row">
                    <Image.PreviewGroup>
                        {renderImages(array)}
                    </Image.PreviewGroup>
                </div>
            )
        )
    }

    const renderCommentList = (array) => {
        // console.log("array", array);
        return (
            (array.length === 0) ? (
                <div />
            ) : (
                <List
                    className="comment-list"
                    header={`${displayCommentList.length} ${displayCommentList.length === 1 ? "comment" : "comments"}`}
                    itemLayout="horizontal"
                    dataSource={displayCommentList}
                    renderItem={item => (
                        <li>
                            <Comment
                                actions={item.actions}
                                author={item.staffProfileName}
                                avatar={item.avatar}
                                content={item.comment}
                                datetime={item.updatedOnDisplay}
                            />
                        </li>
                    )}
                />
            )
        )
    }

    return (
        <div className="container-content allow-overflow">
            <Spin spinning={loading}>
                <div className="content-content">
                    <Descriptions
                        title={
                            <div className="row spaceBetween">
                                <div>
                                    <span>{appState.profileClient.clientProfileName}</span> <Tag>Security Site</Tag>
                                </div>
                                <Button onClick={handleEditClick} shape={appState.broken === true ? "circle" : ""} icon={<EditOutlined />}>
                                    {appState.broken === true ? "" : "Edit"}
                                </Button>
                            </div>
                        }
                        bordered
                        column={{ xs: 1, sm: 2 }}
                    >
                        <Descriptions.Item label="Ticket UID" span={2}>
                            {display.uid}
                            <div className="sub-italic">
                                {
                                    display.createdOnDisplay !== display.updatedOnDisplay ? (
                                        <span>
                                            Last updated: {display.updatedOnDisplay}
                                            <br />
                                            Created On: {display.createdOnDisplay}
                                        </span>
                                    ) : (
                                        <span>Created On: {display.createdOnDisplay}</span>
                                    )
                                }
                            </div>
                        </Descriptions.Item>

                        <Descriptions.Item label="Priority">{display.priority}</Descriptions.Item>
                        <Descriptions.Item label="Status">
                            <Tag color={display.statusColour}>
                                {display.statusDisplay}
                            </Tag>
                        </Descriptions.Item>
                        <Descriptions.Item label="Client">{display.clientDisplay}</Descriptions.Item>
                        <Descriptions.Item label="Client Email">{display.clientEmail}</Descriptions.Item>
                        <Descriptions.Item label="Client Contact Number">{display.clinetPhoneNo}</Descriptions.Item>
                        <Descriptions.Item label="Site">{display.siteDisplay}</Descriptions.Item>
                        <Descriptions.Item label="Category">{display.category}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                        bordered
                        column={{ xs: 1, sm: 2 }}
                        layout="vertical"
                    >
                        <Descriptions.Item label="Summary" span={2}>{display.title}</Descriptions.Item>
                        <Descriptions.Item label="Description" span={2}>{display.description}</Descriptions.Item>
                        <Descriptions.Item label="Upload Image(s)" span={2}>
                            <Spin spinning={loadingImageList}>
                                {/* {display.imagesKey} */}
                                {/* {renderUploadImages(display.imagesKey)} */}
                                {/* {renderUploadImages(display.imagesKey !== undefined ? JSON.parse(display.imagesKey) : [])} */}
                                {renderUploadImages(imageListSource)}
                            </Spin>
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                        bordered
                        column={{ xs: 1, sm: 2 }}
                    >
                        <Descriptions.Item label="Submitted By">{display.submittedByStaffName}</Descriptions.Item>
                        {
                            ticket.status === "CLOSED" ?
                                (
                                    <Descriptions.Item label="Closed By">{display.closedByStaffName}</Descriptions.Item>
                                ) : ""
                        }
                    </Descriptions>
                </div>
            </Spin>
            <Spin spinning={loadingCommentList}>
                <div className="content-content">
                    {renderCommentList(displayCommentList)}
                    {
                        ticket.status === "CLOSED" ? ("") : (
                            <div>
                                <Form
                                    form={form}
                                    layout="vertical"
                                    onFinish={onFinish}
                                    validateMessages={validateMessages}
                                    requiredMark={false}
                                >
                                    <Form.Item
                                        label="Comment"
                                        name="comment"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <TextArea disabled={ticket.status === "CLOSED" ? true : false} rows={4} autoComplete="off" placeholder="Enter comment" onChange={handleFormChange} />
                                    </Form.Item>
                                    <div className="button">
                                        <Popconfirm title={
                                            <div>
                                                Are you sure?<br />
                                                No amendments can be made once comment is submitted.
                                            </div>
                                        } onConfirm={() => onFinish(form.getFieldsValue())} okText="Yes" cancelText="No" disabled={disabledSubmit}>
                                            {/* <Button type="primary" htmlType="submit" disabled={form.getFieldValue("comment") === undefined || form.getFieldValue("comment") === "" ? true : false}>Submit</Button> */}
                                            <Button type="primary" htmlType="submit" disabled={disabledSubmit}>Submit</Button>
                                        </Popconfirm>
                                    </div>
                                </Form>
                                {
                                    ticket.status === "CLOSED" ? "" :
                                        (
                                            <div>
                                                <br /><br /><br />
                                                <Popconfirm title={
                                                    <div>
                                                        Are you sure?<br />
                                                        No amendments can be made to this ticket once the case is closed.
                                                    </div>
                                                } onConfirm={handleCloseCaseClick} okText="Yes" cancelText="No">
                                                    <Button type="primary" style={{ backgroundColor: "#D0342C", border: "#D0342C" }}>Close Case</Button>
                                                </Popconfirm>
                                            </div>
                                        )
                                }
                            </div>
                        )
                    }
                </div>
            </Spin>
            {Prompt}
        </div>
    )
}

export default TicketRecordsProfile