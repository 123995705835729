import React, { useEffect, useState } from "react"
import { Menu } from "antd"
import { AlertOutlined, CarryOutOutlined, ClockCircleOutlined, CompassOutlined, ContactsOutlined, EnvironmentOutlined, ExceptionOutlined, FileDoneOutlined, FileSearchOutlined, HomeOutlined, LoginOutlined, SafetyCertificateOutlined, SearchOutlined, ShakeOutlined, SolutionOutlined, TrophyOutlined, UserOutlined, UserSwitchOutlined } from "@ant-design/icons"
import { NavLink } from "react-router-dom"

import path from "../utils/pathSettings"

const { SubMenu } = Menu

const MenuApp = (props) => {
    const { collapsed, setCollapsed, location } = props
    const getPathSubMenuKey = (path) => {
        const array = path.split("/")
        let subMenuKey = "/"

        for (let i = 0; i < array.length; i++) {
            if (array[i].length !== 0) {
                subMenuKey += array[i]
                break
            }
        }

        return subMenuKey
    }
    const [selectedKeys, setSelectedKeys] = useState(location.pathname)
    const [openKeys, setOpenKeys] = useState(getPathSubMenuKey(location.pathname))

    useEffect(() => {
        setSelectedKeys(location.pathname)
        setOpenKeys(getPathSubMenuKey(location.pathname))
    }, [location])

    const handleClick = () => {
        if (window.innerWidth <= 768) {
            setCollapsed(true)
        }
    }

    return (
        <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[selectedKeys]}
            defaultOpenKeys={[openKeys]}
            selectedKeys={[selectedKeys]}
            style={
                collapsed === true ? (
                    { position: "fixed", top: "64px", width: "0", height: "90vh", overflow: "auto", }
                ) : (
                    { position: "fixed", top: "64px", width: "200px", height: "90vh", overflow: "auto" }
                )
            }
            onClick={handleClick}
            className="scrollbar"
        >
            {/* <Menu.Item key={path("home")} icon={<HomeOutlined />}>
                <NavLink to={path("home")}>Home</NavLink>
            </Menu.Item> */}
            <Menu.Item key={path("patrolRecords")} icon={<ShakeOutlined />}>
                <NavLink to={path("patrolRecords")}>Patrol Records</NavLink>
            </Menu.Item>
            <Menu.Item key={path("ticketRecords")} icon={<FileSearchOutlined />}>
                <NavLink to={path("ticketRecords")}>Records</NavLink>
            </Menu.Item>
        </Menu>
    )
}

export default MenuApp