import React, { useContext, useEffect, useState } from "react"
import { Button, DatePicker, Descriptions, Empty, Form, Image, Input, Modal, notification, Popconfirm, Select, Spin, Tag, Upload } from "antd"
import { CheckOutlined, CloseOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import API from "@aws-amplify/api"
import { Storage } from "@aws-amplify/storage"
import moment from "moment"
import combineQuery from 'graphql-combine-query'
import gql from 'graphql-tag'
import { print } from 'graphql/language/printer'

import { createTicket, createTicketFile, submitIncident } from "../backend/graphql/mutations"
import path from "../utils/pathSettings"
import { getAwsExport } from "../utils/awsExportSettings"
import { AppContext } from "../contexts/AppContext"
import imgEmptyImage192 from "../media/Image-EmptyImage-192.png"
import useUnsavedChangesWarning from "../hooks/useUnsavedChangesWarning"

const { Option } = Select
const { TextArea } = Input

const TicketRecordsCreate = () => {
    const { appState, actionSetPageTitle } = useContext(AppContext)
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const dateFormatList = ['DD MMM YYYY HH:mm']
    const validateMessages = {
        required: 'This field is required.',
    }
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    )
    const [state, setState] = useState({
        fileList: []
    })
    const history = useHistory()
    const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning()

    const uploadFile = async (file) => {
        // console.log(file);
        const user = JSON.parse(localStorage.getItem("isap_cognitouser_agency"))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)

        const fileName = "t_" + moment().utc().format("YYYY-MM-DD_HH:mm:ss") + "_" + file.name
        const fileType = file.type

        try {
            const result = await Storage.put("ticket/" + profiles[0].securityAgencyID + "/" + fileName, file, {
                contentType: fileType
            })
            // console.log(result.key);
            return result.key
        }
        catch (error) {
            console.log("error: ", error);
            notification.error({
                message: error
            })
        }
    }

    const createTicketRecord = async (values, type) => {
        setLoading(true)
        
        const user = JSON.parse(localStorage.getItem("isap_cognitouser_agency"))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        console.log("user", user);
        console.log("profiles", profiles);
        try {
            const variables = {
                create: {
                    securityAgencyID: profiles[0].securityAgencyID,
                    clientProfileID: values.client,

                    submittedBy: user.username,
                    submittedByStaffProfileID: profiles[0].staffProfileID,
                    // submittedByDesignation: 
                    submittedTimestamp: moment().toISOString().split('.')[0],

                    status: values.status,
                    category: values.category,
                    title: values.summary,
                    description: values.description,
                    priority: values.priority,
                }
            }

            if (values.site) {
                variables.create = {
                    ...variables.create,
                    securitySiteID: appState.siteList.find(s => s.id == values.site)?.securitySiteID ?? null,
                    siteProfileID: values.site,
                }
            }
            console.log('createTicket', variables);
            const result = await API.graphql({
                query: createTicket,
                variables,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })
            console.log("result", result);


            // upload file if any
            if (result?.data?.result && state.fileList.length > 0) {
                let imageKeyList = []
                for (let i = 0; i < state.fileList.length; i++) {

                    const file = state.fileList[i].originFileObj
                    const imageKey = await uploadFile(file)
                    imageKeyList.push(imageKey)
                    // console.log("imageKey", imageKey);
                }

                const createTicketFileVariables = []
                for (let k of imageKeyList) {
                    console.log(k)
                    createTicketFileVariables.push({
                        create: {
                            ticketID: result.data.result.id,
                            bucket: getAwsExport().aws_user_files_s3_bucket,
                            key: k,
                            region: getAwsExport().aws_user_files_s3_bucket_region,
                        }
                    })
                }
                console.log('createTicketFileVariables', createTicketFileVariables)

                let combineQueryFn = combineQuery('CompositeMutation');
                combineQueryFn = combineQueryFn.addN(gql`${createTicketFile}`, createTicketFileVariables,
                    (name, index) => {
                        return `${name}2_${index}`
                    }, (name, index) => {
                        return `${name}2_${index}`
                    });

                const { document, variables: variables2 } = (() => combineQueryFn)();

                console.log("TicketFile", print(document), variables2);

                const result2 = await API.graphql({
                    query: print(document),
                    variables: variables2,
                    authMode: "AMAZON_COGNITO_USER_POOLS"
                })
                console.log("result", result2);
            }

            setPristine()
            notification.success({
                message: "Created successfully"
            })
            history.push(path("ticketRecordsProfile", [result.data.result.id]))
        }
        catch (error) {
            console.log("error:", error);
            notification.error({
                message: "Unable to create ticket"
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        form.setFieldsValue({
            status: "OPEN",
            priority: "MEDIUM"
        })

        actionSetPageTitle("Create Ticket", true, path("ticketRecords"))

        return () => {
            actionSetPageTitle("", false, "")
        }
    }, [])

    useEffect(() => {
        if (appState.fixedListLoaded === true) {
            form.setFieldsValue({
                // incidentDatetimeStart: moment(new Date(incident.incidentDatetimeStart.replace(/-/g, "/") + " UTC")),
                // incidentDatetimeEnd: moment(new Date(incident.incidentDatetimeEnd.replace(/-/g, "/") + " UTC")),
            })
            setLoading(false)
        }
    }, [appState.fixedListLoaded])

    const onFinish = async (values, type) => {
        console.log("on finish", values);
        // console.log("uploaded images", state);
        await form.validateFields()
        // setLoading(true)
        // // const user = JSON.parse(localStorage.getItem("isap_cognitouser_agency"))
        // // const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        // // console.log("user", user);
        // // console.log("profiles", profiles);

        // await createTicketRecord(values, type)
        // // setLoading(true)
        // // setPristine()
        // // const timer = setTimeout(() => {
        // //     clearTimeout(timer)
        // //     notification.success({
        // //         message: "Created successfully"
        // //     })
        // //     history.push(path("incident")) // redirect to created report page from backend response
        // // }, 1000)
    }

    const handleDatePickerChange = (date, dateString) => {
        // console.log(date, dateString);
        setDirty()
    }

    const handleFormChange = () => {
        setDirty()
    }

    const handleCancel = () => {
        setState({ ...state, previewVisible: false })
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setState({
            ...state,
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        })
    }

    const handleChange = (file) => {
        let { fileList } = file
        setState({ ...state, fileList: fileList })
        setDirty()
    }

    const handleCancelUpdate = () => {
        history.push(path("ticketRecords"))
    }

    const disabledDate = (current) => {
        // Cannot select days next day onwards
        return current > moment().endOf('day');
    }

    const renderListOptions = (array, stringId) => {

        const listOptions = array.map((item, index) => {
            if (stringId !== undefined && stringId === 1) {
                return (
                    <Option key={item.id} value={item.id.toString()}>{item.name}</Option>
                )
            }
            else {
                return (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                )
            }
        })

        return listOptions
    }

    const renderSiteListOptions = (array) => {
        // console.log("array", array);
        if (array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                )
            })

            return listOptions
        }
        else {
            return (
                <div />
            )
        }
    }

    const renderImages = (array) => {
        const images = array.map((item) => {
            return (
                <div>
                    <Image
                        width={100}
                        src={item.key !== null ? item.key : ""}
                        fallback={imgEmptyImage192}
                    />
                    &nbsp;&nbsp;
                </div>
            )
        })
        return images
    }

    return (
        <div className="container-content allow-overflow">
            <Spin spinning={loading}>
                <div className="content-content">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        validateMessages={validateMessages}
                        requiredMark={true}
                    >
                        <Descriptions
                            title={
                                <div className="row spaceBetween">
                                    <div>
                                        <span>{appState.profileClient.clientProfileName}</span> <Tag>Ticket</Tag>
                                    </div>
                                    <div>
                                        <Button onClick={handleCancelUpdate} shape={appState.broken === true ? "circle" : ""} icon={<CloseOutlined />}>
                                            {appState.broken === true ? "" : "Cancel"}
                                        </Button>
                                        &nbsp;&nbsp;
                                        <Popconfirm title={
                                            <div>
                                                Are you sure?
                                            </div>
                                        } onConfirm={() => onFinish(form.getFieldsValue(), "submit")} okText="Yes" cancelText="No" placement="topRight">
                                            <Button type="primary" htmlType="submit" shape={appState.broken === true ? "circle" : ""} icon={<CheckOutlined />}>
                                                {appState.broken === true ? "" : "Submit"}
                                            </Button>
                                        </Popconfirm>
                                    </div>
                                </div>
                            }
                            column={{ xs: 1, sm: 2 }}
                        />
                        <Form.Item
                            label="Status"
                            name="status"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select status"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleFormChange}
                            >
                                <Option key="OPEN" value="OPEN">Open</Option>
                                <Option key="CLOSED" value="CLOSED">Closed</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Summary"
                            name="summary"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Enter summary" onChange={handleFormChange} />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <TextArea rows={4} autoComplete="off" placeholder="Enter description" onChange={handleFormChange} />
                        </Form.Item>
                        <Form.Item
                            label="Category"
                            name="category"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select category"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {renderListOptions([
                                    {
                                        id: "ENQUIRY",
                                        name: "Enquiry"
                                    },
                                    {
                                        id: "FEEDBACK",
                                        name: "Feedback"
                                    },
                                    {
                                        id: "COMPLIMENT",
                                        name: "Compliment"
                                    },
                                    {
                                        id: "COMPLAINT",
                                        name: "Complaint"
                                    },
                                    {
                                        id: "INVESTIGATE",
                                        name: "Investigate"
                                    },
                                    {
                                        id: "OTHERS",
                                        name: "Others"
                                    },
                                ])}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Priority"
                            name="priority"
                        >
                            <Select
                                showSearch
                                placeholder="Select priority"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {renderListOptions([
                                    {
                                        id: "HIGHEST",
                                        name: "Highest"
                                    },
                                    {
                                        id: "HIGHT",
                                        name: "High"
                                    },
                                    {
                                        id: "MEDIUM",
                                        name: "Medium"
                                    },
                                    {
                                        id: "LOW",
                                        name: "Low"
                                    },
                                    {
                                        id: "LOWEST",
                                        name: "Lowest"
                                    },
                                ])}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Client"
                            name="client"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select client"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {renderListOptions(appState.clientList)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Site"
                            name="site"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select site"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value={0}>None</Option>
                                {renderListOptions(appState.siteList)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Upload Image(s)"
                        >
                            <Upload
                                action={getBase64}
                                listType="picture-card"
                                fileList={state.fileList}
                                onPreview={handlePreview}
                                onChange={(file) => handleChange(file)}
                                multiple={true}
                                accept={"image/*"}
                            >
                                {state.fileList.length >= 5 ? null : uploadButton}
                            </Upload>
                            <Modal
                                visible={state.previewVisible}
                                title={state.previewTitle}
                                footer={null}
                                onCancel={handleCancel}
                            >
                                <img alt="upload preview" style={{ width: '100%' }} src={state.previewImage} />
                            </Modal>
                        </Form.Item>
                        <div className="button">
                            <Button onClick={handleCancelUpdate}>Cancel</Button>
                            &nbsp;&nbsp;
                            <Popconfirm title={
                                <div>
                                    Are you sure?
                                </div>
                            } onConfirm={() => onFinish(form.getFieldsValue(), "submit")} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="submit">Submit</Button>
                            </Popconfirm>
                        </div>
                    </Form>
                </div>
            </Spin>
            {Prompt}
        </div>
    )
}

export default TicketRecordsCreate