import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Badge, Button, Descriptions, Empty, notification, Spin, Table, Tag } from "antd"
import { FormOutlined, PlusOutlined } from "@ant-design/icons"
import moment from "moment"
import API from "@aws-amplify/api"

import path from "../utils/pathSettings"
import { AppContext } from "../contexts/AppContext"
import FilterTicket from './Component-Filter-Ticket'
import FilterAppliedTicket from './Component-FilterApplied-Ticket'
import { listTicket } from "../backend/graphql/queries"

const TicketRecords = () => {
    const { appState, actionSetPageTitle } = useContext(AppContext)
    const history = useHistory()
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0,
            defaultPageSize: 20,
            showSizeChanger: true,
        },
        loading: false
    })
    const [displayList, setDisplayList] = useState([])
    const [draftCount, setDraftCount] = useState(0)
    const [values, setValues] = useState({
        search: "",
        dateRange: [],
        priority: 0,
        status: 0,
        site: 0,
        client: 0,
        category: 0,
    })

    const getTicketRecords = async (props, values) => {
        // console.log("values", values);
        setTable({
            ...table,
            loading: true
        })

        const user = JSON.parse(localStorage.getItem("isap_cognitouser_client"))
        // console.log("user", user);
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        // console.log("profiles", profiles);
        // console.log("profile", profiles[0].securityAgencyID);

        try {
            let filter = {
                securityAgencyID: {
                    eq: profiles[0].securityAgencyID // current fixed to first profile, need to have an attribute to store last agency profile
                },
                and: [
                    {
                        // submittedTimestamp: { ge: values.dateRange[0].toISOString().split(".")[0] }
                        submittedTimestamp: { ge: values.dateRange[0].utc().format("YYYY-MM-DD 00:00:00") }
                    },
                    {
                        // submittedTimestamp: { le: values.dateRange[1].toISOString().split(".")[0] }
                        submittedTimestamp: { le: values.dateRange[1].utc().format("YYYY-MM-DD 23:59:59") }
                    },
                ]
            }

            if (values.search !== "") {
                filter = {
                    ...filter,
                    or: [
                        // { uid: { contains: values.search } },
                        { title: { contains: values.search } },
                        // { submittedBy: { contains: values.search } }
                    ]
                }
            }

            if (values.priority !== 0) {
                filter = {
                    ...filter,
                    priority: { eq: values.priority }
                }
            }

            if (values.status !== 0) {
                filter = {
                    ...filter,
                    status: { eq: values.status }
                }
            }

            if (values.site !== 0) {
                filter = {
                    ...filter,
                    siteProfileID: { eq: values.site }
                }
            }

            if (values.client !== 0) {
                filter = {
                    ...filter,
                    clientProfileID: { eq: values.client }
                }
            }

            if (values.category !== 0) {
                filter = {
                    ...filter,
                    category: { eq: values.category }
                }
            }

            const variables = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    agencyID: profiles[0].securityAgencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                    orderby: "`submittedTimestamp` ASC"
                },
                filter: filter,
            }
            // console.log('variables', variables);
            const result = await API.graphql({
                query: listTicket,
                variables,
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })
            // console.log("result", result);
            const data = result.data.result
            // console.log("data", data);

            setTable({
                ...table,
                data: data.result,
                loading: data.result.length === 0 ? false : true,
                pagination: {
                    ...table.pagination,
                    current: props.current,
                    offset: props.offset,
                    pageSize: props.pageSize,
                    total: props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            })
        }
        catch (error) {
            console.log("error:", error);
            notification.error({
                message: "Unable to retrieve incident reports"
            })
            setTable({
                ...table,
                loading: false,
            })
        }
    }

    useEffect(() => {
        // console.log(moment());
        const currentDatetime = moment()
        let _values = {}
        if (sessionStorage.getItem("isap_agency_ticket_records") !== null) {
            // console.log(moment(JSON.parse(sessionStorage.getItem("isap_agency_ticket_records")).dateRange[0]));
            _values = {
                ...values,
                ...JSON.parse(sessionStorage.getItem("isap_agency_ticket_records")),
                dateRange: [
                    moment(JSON.parse(sessionStorage.getItem("isap_agency_ticket_records")).dateRange[0]),
                    moment(JSON.parse(sessionStorage.getItem("isap_agency_ticket_records")).dateRange[1]),
                ]
            }
        }
        else {
            _values = {
                ...values,
                dateRange: [
                    moment(new Date(currentDatetime.format("YYYY-MM-DD 00:00:00"))).subtract(1, "year"),
                    moment(new Date(currentDatetime.format("YYYY-MM-DD 23:59:59"))),
                ]
            }
        }
        setValues(_values)
        // getTicketRecords(table.pagination, _values)

        actionSetPageTitle("Ticket - Records")

        return () => {
            actionSetPageTitle("")
        }
    }, [])

    useEffect(() => {
        if (table.data !== -1 && appState.fixedListLoaded === true) {
            let array = []
            for (let k = 0; k < table.data.length; k++) {
                let object = {}

                let siteDisplay = appState.siteList.find(s => s.id == table.data[k].siteProfileID)?.name;
                // let clientDisplay = appState.clientList.find(s => s.id == table.data[k].clientProfileID).name;
                // for (let i = 0; i < appState.siteList.length; i++) {
                //     if (table.data[k].siteProfileID === appState.siteList[i].id) {
                //         siteDisplay = appState.siteList[i].name
                //         break
                //     }
                // }

                object = {
                    ...table.data[k],
                    siteDisplay,
                    // clientDisplay,
                    summary: table.data[k].title,
                }
                array.push(object)
            }

            setDisplayList(array)
            setTable({
                ...table,
                loading: false
            })
        }
    }, [table.data, appState.fixedListLoaded])

    const handleTableChange = (paginate) => {
        // getTicketRecords({
        //     ...table.pagination,
        //     current: paginate.current,
        //     pageSize: paginate.pageSize,
        //     offset: paginate.current * paginate.pageSize - paginate.pageSize,
        // }, values)
    }

    const handleOnRow = (record, rowIndex) => {
        return {
            onClick: (event) => {
                // console.log(event.target.className);
                if (event.target.className !== "noRowClick" && event.target.className !== "") {
                    history.push(path("ticketRecordsProfile", [record.id]))
                }
            },
        }
    }

    const handleScoll = (e) => {
        // console.log("scroll height: ", e.target.scrollHeight);
        // console.log("scroll top", e.target.scrollTop);
        // console.log("client height", e.target.clientHeight);
        const atTop = e.target.scrollTop === 0
        const atBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
        if (atBottom) {
            // console.log("BOTTOM");
        }
        else if (atTop) {
            // console.log("TOP");
        }
    }

    const handleCreateClick = () => {
        history.push(path("ticketRecordsCreate"))
    }

    const onFinish = (values) => {
        // console.log("on finish", values);
        let _values = {
            ...values,
            dateRange: [
                moment(values.dateRange[0].format("YYYY-MM-DD 00:00:00")),
                moment(values.dateRange[1].format("YYYY-MM-DD 23:59:59")),
            ]
        }
        sessionStorage.setItem("isap_agency_ticket_records", JSON.stringify(_values))
        setValues(_values)
        // getTicketRecords(table.pagination, _values)
    }

    const columns = [
        {
            title: "Ticket UID",
            dataIndex: "uid"
        },
        {
            title: "Summary",
            dataIndex: "summary"
        },
        {
            title: "Category",
            dataIndex: "category"
        },
        {
            title: "Priority",
            dataIndex: "priority"
        },
        {
            title: "Client",
            dataIndex: "clientDisplay",

        },
        {
            title: "Site",
            dataIndex: "siteDisplay",

        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text, record) => {
                const colour = text === "CLOSED" ? "green" : (text === "OPEN" ? "orange" : "cyan")
                return (
                    <Tag color={colour}>
                        {text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase()}
                    </Tag>
                )
            }
        },
        {
            title: "Created On",
            dataIndex: "createdOn",
            render: (text, record) => {
                return (
                    moment(new Date(text.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm')
                )
            }
        },
        {
            title: "Last Updated",
            dataIndex: "updatedOn",
            render: (text, record) => {
                return (
                    moment(new Date(text.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm')
                )
            }
        },
    ]

    return (
        <div className="container-content allow-overflow">
            <div className="content-content">
                <Descriptions title={
                    <div className="row spaceBetween">
                        <div>
                            <span>{appState.profileClient.clientProfileName}</span> <Tag>Ticket</Tag>
                        </div>
                        <Button onClick={handleCreateClick} type="primary" shape={appState.broken === true ? "circle" : ""} icon={<PlusOutlined />}>
                            {appState.broken === true ? "" : "Create"}
                        </Button>
                    </div>
                } />
                <FilterTicket onFinish={onFinish} values={values} />
                <FilterAppliedTicket onFinish={onFinish} values={values} />
            </div>
            <Table
                className="above-md"
                columns={columns}
                rowKey={record => record.id}
                dataSource={displayList}
                pagination={table.pagination}
                loading={table.loading}
                scroll={{ x: 1200, y: "55vh" }}
                onChange={handleTableChange}
                onRow={handleOnRow}
                rowClassName={"table-row-clickable"}
            />
        </div>
    )
}

export default TicketRecords