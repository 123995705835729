import React, { useContext, useEffect, useState } from "react"
import { CloseOutlined } from '@ant-design/icons';

import { AppContext } from "../contexts/AppContext"

const FilterAppliedPatrol = (props) => {
    const { appState } = useContext(AppContext)
    const checkpointsPage = props.checkpointsPage !== undefined ? props.checkpointsPage : false
    const routesPage = props.routesPage !== undefined ? props.routesPage : false
    const [values, setValues] = useState({})
    const [display, setDisplay] = useState({})
    const [visibility, setVisibility] = useState(false)

    useEffect(() => {
        setValues(props.values)

        let dateRange = ""
        if (!checkpointsPage && !routesPage && props.values.dateRange.length === 2) {
            dateRange = props.values.dateRange[0].format("DD MMM YYYY") + " - " + props.values.dateRange[1].format("DD MMM YYYY")
        }

        let status = ""
        if (!checkpointsPage && props.values.status !== 0) {
            status = props.values.status.slice(0, 1).toUpperCase() + props.values.status.slice(1).toLowerCase()
        }

        let site = ""
        if (props.values.site !== 0) {
            for (let i = 0; i < appState.siteList.length; i++) {
                if (props.values.site === appState.siteList[i].securitySiteID) {
                    site = appState.siteList[i].name
                    break
                }
            }
        }

        if (props.values.search === "" && dateRange === "" && status === "" && site === "") {
            setVisibility(false)
        }
        else {
            setVisibility(true)
        }

        setDisplay({
            search: props.values.search,
            dateRange: dateRange,
            status: status,
            site: site,
        })

    }, [props])

    const handlefilterClick = (e, id) => {
        props.onFinish({
            ...values,
            [id]: id === "search" ? ("") : (0)
            // [id]: id === "search" ? ("") : (id === "dateRange" ? ([]) : (0))
        })
    }

    return (
        <div>
            {
                visibility === true ? (
                    <div className="filter-applied">
                        {
                            display.dateRange !== "" ? (
                                <div className="filter-applied-item">
                                    Patrol Start Date Range: <b>{display.dateRange}</b>
                                </div>
                            ) : (
                                <div />
                            )
                        }
                        {
                            display.search !== "" ? (
                                <div className="filter-applied-item">
                                    Search: <b>{display.search}</b>&nbsp;
                                    <span className="close" onClick={(e) => handlefilterClick(e, "search")}>
                                        <CloseOutlined />
                                    </span>
                                </div>
                            ) : (
                                <div />
                            )
                        }
                        {
                            display.status !== "" ? (
                                <div className="filter-applied-item">
                                    Status: <b>{display.status}</b>&nbsp;
                                    <span className="close" onClick={(e) => handlefilterClick(e, "status")}>
                                        <CloseOutlined />
                                    </span>
                                </div>
                            ) : (
                                <div />
                            )
                        }
                        {
                            display.site !== "" ? (
                                <div className="filter-applied-item">
                                    Site: <b>{display.site}</b>&nbsp;
                                    <span className="close" onClick={(e) => handlefilterClick(e, "site")}>
                                        <CloseOutlined />
                                    </span>
                                </div>
                            ) : (
                                <div />
                            )
                        }
                    </div>
                ) : (
                    <div />
                )
            }

        </div>
    )
}

export default FilterAppliedPatrol